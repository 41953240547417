<template>
    <vue-perfect-scrollbar
          ref="Tabs"
          :settings="perfectScrollbarSettings"
          class="tabs-scroll-area"
          :always-show-scrollbar="true"
        >

    <div class="sports-menu" @click="ChangeType();">
      프리매치
    </div>
    <div style="margin-left:10px;" class="sports-menu selected">
      스페셜
    </div>


    <b-tabs align="right" style="width:max-content;display:inline;" class="d-none d-lg-inline">
      <b-tab active title="🏆 전체" @click="onTabChange(0)"></b-tab>
      <b-tab title="⚽ 축구" @click="onTabChange(1)"></b-tab>
      <b-tab title="⚾ 야구" @click="onTabChange(16)" v-if="users.use_baseball==1"></b-tab>
      <b-tab title="🏀 농구" @click="onTabChange(18)" v-if="users.use_basketball==1"></b-tab>
      <b-tab title="🏐 배구" @click="onTabChange(91)" v-if="users.use_volleyball==1"></b-tab>
      <b-tab title="🏒 아이스하키" @click="onTabChange(17)" v-if="users.use_hockey==1"></b-tab>
    </b-tabs>

    <!--<b-form-select :options="options" v-model="selected" align="right" style="width:max-content;display:inline;" class="d-inline d-lg-none small-tab"></b-form-select>-->
    <select v-model="result" @change="onSelectChange()" class="d-inline d-lg-none b-select" style="float:right;">
      <option value="0" selected style="background-color:#242a3a;color:white;">🏆 전체</option>
      <option value="1"  style="background-color:#242a3a;color:white;">⚽ 축구</option>
      <option value="16" style="background-color:#242a3a;color:white;">⚾ 야구</option>
      <option value="18" style="background-color:#242a3a;color:white;">🏀 농구</option>
      <option value="91" style="background-color:#242a3a;color:white;">🏐 배구</option>
      <option value="17" style="background-color:#242a3a;color:white;">🏒 아이스하키</option>
    </select>
    <!--
    <b-tabs align="right" style="width:max-content;display:inline;" class="d-inline d-lg-none small-tab">
      <b-tab active title="⚽" @click="onTabChange(1)"></b-tab>
      <b-tab title="⚾" @click="onTabChange(16)" v-if="users.use_baseball==1"></b-tab>
      <b-tab title="🏀" @click="onTabChange(18)" v-if="users.use_basketball==1"></b-tab>
      <b-tab title="🏐" @click="onTabChange(91)" v-if="users.use_volleyball==1"></b-tab>
      <b-tab title="🏒" @click="onTabChange(17)" v-if="users.use_hockey==1"></b-tab>
      <b-tab title="👊" @click="onTabChange(162)" v-if="users.use_mma==1"></b-tab>
    </b-tabs>
    -->

    </vue-perfect-scrollbar>

</template>

<script>
import { BTabs, BTab, BCardText, BFormSelect } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EventBus from '../../event-bus';

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    VuePerfectScrollbar,
    EventBus,
  },
  setup(){
    const perfectScrollbarSettings = {
      handlers : ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      suppressScrollY : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    return{
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      users : [],
      result:0,
    }
  },
  created() {
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
      this.bet_money = 0;
    }
  },
  methods: {
    onTabChange (id) {
      this.result = id;
      this.$parent.getData(1, id);
    },
    ChangeType() {
      this.$router.replace('/debug2');
    },
    onSelectChange(){
      this.$parent.getData(1, this.result);
    }
  }
}
</script>

<style>
.tabs-scroll-area {width:100%;height:45px;}
.small-tab li {font-size:18px;}
.sports-menu { float:left;width:80px;height:34px;border:1px solid white;color:white; line-height:34px; text-align:center;cursor:pointer;}
.sports-menu:hover { background-color:#474747; }
.selected {border:1px solid #ffe588;color:#ffe588;}

.nav-tabs .nav-link {
  padding: 10px 10px;
}

@media (max-width: 991px) {
  .nav-tabs .nav-link {
    padding: 5px 10px;
  }
}

.b-select {
  width:max-content;
  display:inline;
  padding:7px 10px;
  border:1px solid white;
  color:white;
  background-color:transparent;
}
select:hover, select:focus {
  border:1px solid white;
}
</style>
